import { ReactComponent as CameraIcon } from "./assets/camera_alt-24px.svg";
import Specimen_IMAGE from "./assets/Group 50.png";
//@ts-ignore
import SimpleLoader from "./Loader";
import React, { ChangeEvent, useEffect, useRef } from "react";
import { trackEvent } from "./utils/tracking";

interface Props {
  loading: boolean;
  onImage: (file: File) => void;
}

export const Welcome = ({ loading, onImage }: Props) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  function onImageSelected(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files?.length) {
      return;
    }

    trackEvent("foto uploaded", {});
    onImage(e.target.files[0]);
  }

  useEffect(() => {
    trackEvent("opened welcome screen", {});
  }, []);

  return (
    <div className="welcome">
      <div className="welcome-body">
        <input
          style={{ display: "none" }}
          ref={inputFileRef}
          id="myFileInput"
          type="file"
          accept="image/*;capture=camera"
          onChange={onImageSelected}
        />

        <div className="welcome-image">
          <img alt="specimen" src={Specimen_IMAGE}></img>
        </div>

        <div className="welcome-title">Vítejte v InstaClaim mining</div>

        <div className="welcome-perex">
          Aplikace na ukázku extrakce informací z vašich dokumentů. Vyfoťte si
          zadní stranu vašeho <b>malého technického průkazu</b> a my Vám ukážeme
          jak extrakce funguje v praxi.
        </div>
      </div>

      <div
        className="button-camera"
        style={{ cursor: loading ? "none" : "pointer" }}
        onClick={() => {
          !loading && inputFileRef.current!.click();
        }}
      >
        {loading ? <SimpleLoader color="white" /> : <CameraIcon></CameraIcon>}
      </div>
    </div>
  );
};
