import mixpanel from "mixpanel-browser";
import { isDev, isProd } from "./env";
import { detectBrowser } from "./detectBrowser";
import { MiningData } from "../types";

const browser = detectBrowser();

const mixpanelToken = isProd()
  ? "6df837e19cac6ada60cce7b076251a1a"
  : "9ef35b47428e7f87ea46f6334c4d79ba";

mixpanel.init(mixpanelToken, { debug: isDev() });

mixpanel.register({
  userAgent: navigator.userAgent,
  browserName: browser?.name,
  browserVersion: browser?.version,
  browserOs: browser?.os,
  moduleName: "mining - web demo",
});

export const trackEvent = (
  eventName: string,
  payload?: Record<string, unknown> | undefined
) => {
  mixpanel.track(eventName, payload);
  // firebase.analytics().logEvent(eventName, payload);
};



export const trackResult = (result: any, processingTime: number) => {
  const debugcode = result?.data?.data?.debug_data?.hash as string | undefined;
  const miningResult = result?.data?.data?.pages?.[0]?.gathered_data as
    | MiningData
    | undefined;

  const results = Object.entries(miningResult || {}).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value?.text,
      [`${key}_confidence`]: value?.confidence,
    }),
    {}
  );

  trackEvent("results returned", {
    ...results,
    imageRef: debugcode,
    processingTime,
  });
};
