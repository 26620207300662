import "@firebase/functions";
import "@firebase/firestore";
// import { use100vh } from "react-div-100vh";

import * as firebase from "firebase/app";

import React, { useState } from "react";
import { PhotoProgress } from "./types";
import { Uploader } from "./utils/Uploader";
export default ({ id }: { id: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [items, setItems] = useState<PhotoProgress[]>([]);

  // return <div>{id}</div>;
  return (
    <Uploader
      mine={false}
      classify={false}
      onChange={setItems}
      neukazujNic={true}
      onNewFileRefPath={(path) => {
        firebase
          .firestore()
          .collection("upload-sessions")
          .doc(id)
          .collection("photos")
          .add({ path });
      }}
    />
  );
  //   document.location.pathname;
};
