/* eslint-disable @typescript-eslint/no-unused-vars */
import * as tf from "@tensorflow/tfjs";
import React, {
  TdHTMLAttributes,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Uploader } from "./utils/Uploader";
import { useAsync } from "react-use";
import Loader from "./Loader";
//@ts-ignore
import Webcam from "webcam-easy";
import { triggerAsyncId } from "async_hooks";
// @ts-ignore

export const HavVideo = () => {
  const { loading, value: model, error } = useAsync(async () => {
    const m = await loadTruncatedMobileNet();

    setMessage("zahrejvam model");
    setTimeout(() => {
      zahrejMe(m);
    }, 100);
    // await

    return m;
  });

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [message, setMessage] = useState("Stahuju model");
  const [result, setResult] = useState("---");

  function zahrejMe(model: tf.LayersModel) {
    // return new Promise((resolve, reject) => {
    model.predict(tf.ones([1, 299, 299, 3]));
    setMessage("");
    // });
  }

  const xref = useRef<any>();

  useEffect(() => {
    const webcamElement = document.getElementById("webcam");
    const canvasElement = document.getElementById("canvas");
    const snapSoundElement = document.getElementById("snapSound");

    console.log("WC", webcamElement, xref.current);
    if (webcamElement) {
      const webcam = new Webcam(
        webcamElement,
        "environment",
        canvasElement,
        snapSoundElement
      );

      webcam
        .start()
        .then((result: any) => {
          console.log("webcam started");
          function hon() {
            webcam.snap();
            const image = tf.browser
              //@ts-ignore
              .fromPixels(document.getElementById("canvas"))
              .resizeBilinear([299, 299])

              .expandDims(0);

            const r = predikuj(image.div(255));
            setResult(r);
            // console.log("preper", r);
            setTimeout(() => hon(), 10);
          }
          hon();
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xref, model, message]);

  async function loadTruncatedMobileNet() {
    const mobilenet = await tf.loadLayersModel("/models/kek2/model.json");

    return mobilenet;
    // Return a model that outputs an internal activation.
    // const layer = mobilenet.getLayer("conv_pw_13_relu");
    // return tf.model({ inputs: mobilenet.inputs, outputs: layer.output });
  }

  console.log("TRR", model?.predict, error);
  if (!model || message) {
    return (
      <div>
        <h1>{message}</h1>
        <Loader color="#00BFFF" />
      </div>
    );
  }

  function predikuj(tensor: tf.Tensor<tf.Rank>) {
    const start = new Date();
    //@ts-ignore
    const prdyx = model.predict(tensor).dataSync();
    var prdy = [...prdyx];

    const nejv = prdy.indexOf(Math.max(...prdy));
    const lbl = [
      "predni_sklo",
      "zepredu",
      "zepredu_levy_bok",
      "levy_bok",
      "zezadu_levy_bok",
      "zezadu",
      "zezadu_pravy_bok",
      "pravy_bok",
      "zepredu_pravy_bok",
      "motor",
      "palubni_deska",
      "tachometr",
      "ostatni",
      "VIN",
    ];

    return `${lbl[nejv]} ${Math.round(prdy[nejv] * 100)}% ${(
      new Date().valueOf() - start.valueOf()
    ).toFixed(0)}ms`;
    // console.log("XXXSDDSDSDS===", nejv, lbl[nejv], prdy[nejv]);
  }

  return (
    <div>
      <video
        ref={xref}
        id="webcam"
        autoPlay
        playsInline
        width="640"
        height="480"
      ></video>
      <br></br>
      {result}
      <br></br>
      <canvas id="canvas"></canvas>
    </div>
  );
};
