/* eslint-disable @typescript-eslint/no-unused-vars */
import * as tf from "@tensorflow/tfjs";
import React, {
  TdHTMLAttributes,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Uploader } from "./utils/Uploader";
import { useAsync } from "react-use";
import Loader from "./Loader";
import { triggerAsyncId } from "async_hooks";
// @ts-ignore

export const Hav = () => {
  const { loading, value: model, error } = useAsync(async () => {
    const m = await loadTruncatedMobileNet();

    setMessage("zahrejvam model");
    setTimeout(() => {
      zahrejMe(m);
    }, 100);
    // await

    return m;
  });

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [message, setMessage] = useState("Stahuju model");

  function zahrejMe(model: tf.LayersModel) {
    // return new Promise((resolve, reject) => {
    model.predict(tf.ones([1, 299, 299, 3]));
    setMessage("");
    // });
  }

  async function loadTruncatedMobileNet() {
    const mobilenet = await tf.loadLayersModel("/models/kek2/model.json");

    return mobilenet;
    // Return a model that outputs an internal activation.
    // const layer = mobilenet.getLayer("conv_pw_13_relu");
    // return tf.model({ inputs: mobilenet.inputs, outputs: layer.output });
  }

  console.log("TRR", model?.predict, error);
  if (!model || message) {
    return (
      <div>
        <h1>{message}</h1>
        <Loader color="#00BFFF" />
      </div>
    );
  }

  function predikuj(tensor: tf.Tensor<tf.Rank>) {
    const start = new Date();
    //@ts-ignore
    const prdyx = model.predict(tensor).dataSync();
    var prdy = [...prdyx];

    const nejv = prdy.indexOf(Math.max(...prdy));
    const lbl = [
      "predni_sklo",
      "zepredu",
      "zepredu_levy_bok",
      "levy_bok",
      "zezadu_levy_bok",
      "zezadu",
      "zezadu_pravy_bok",
      "pravy_bok",
      "zepredu_pravy_bok",
      "motor",
      "palubni_deska",
      "tachometr",
      "ostatni",
      "VIN",
    ];

    return `${lbl[nejv]} ${Math.round(prdy[nejv] * 100)}% ${(
      new Date().valueOf() - start.valueOf()
    ).toFixed(0)}ms`;
    // console.log("XXXSDDSDSDS===", nejv, lbl[nejv], prdy[nejv]);
  }
  return (
    <>
      <Uploader
        //@ts-ignore

        onChange={(o) => {
          //@ts-ignore

          //@ts-ignore
          o.forEach((imgo) => {
            //@ts-ignore
            if (imgo.tf) {
              return;
            }
            //@ts-ignore
            imgo.tf = true;
            const file = imgo.file;
            // img.file.
            // setTimeout(() => {
            let reader = new FileReader();
            reader.onload = (e) => {
              // Fill the image & call predict.
              let img = document.createElement("img");
              //@ts-ignore
              img.src = e.target.result;
              //@ts-ignore
              // console.log("ABB", e.target.result);
              img.width = 299;
              img.height = 299;
              img.onload = () => {
                // @ts-ignore
                // console.log("xsxsxs", x.shape);
                // conso;
                const image = tf.browser
                  .fromPixels(img)
                  .resizeBilinear([299, 299])

                  .expandDims(0);

                const r = predikuj(image.div(255));
                // zelvo nic nerikej
                imgo.type = r;
                imgo.loading = false;
                forceUpdate();
                //@ts-ignore
                // uploaderRef.current.forceUpdate();
              };
            };

            // Read in the image file as a data URL.
            reader.readAsDataURL(file);
          });
          // console.log("XXXXASDASDS", o);
        }}
        classify={false}
        mine={false}
        showMobileQr={false}
        autaky={true}
        uploadToFirebase={false}
      ></Uploader>
      <canvas width="1299" height="299" id="kanvas"></canvas>
    </>
  );
};
