import React, { FC } from "react";
import { ReactComponent as Logo } from "./assets/logo.svg";

const Page: FC = ({ children }) => {
  return (
    <div className="page">
      <div
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          height: 80,
          backgroundColor: "white",
          zIndex: 999,
        }}
      >
        <a href="https://instaclaim.ai/">
          <Logo style={{ marginLeft: 30, marginTop: 30, height: 40 }} />
        </a>
      </div>

      {children}
    </div>
  );
};

export default Page;
