import { ReactComponent as CameraIcon } from "./assets/camera_alt-24px.svg";
//@ts-ignore
import SimpleLoader from "./Loader";
import React, { ChangeEvent, useEffect, useRef } from "react";
import { trackEvent } from "./utils/tracking";

interface Props {
  loading: boolean;
  onImage: (file: File) => void;
}

export const WelcomeKoop = ({ loading, onImage }: Props) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  function onImageSelected(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files?.length) {
      return;
    }

    trackEvent("foto uploaded", {});
    onImage(e.target.files[0]);
  }

  useEffect(() => {
    trackEvent("opened welcome screen", {});
  }, []);

  return (
    <div>
      <input
        style={{ display: "none" }}
        ref={inputFileRef}
        id="myFileInput"
        type="file"
        accept="image/*;capture=camera"
        onChange={onImageSelected}
      />

      <div
        className="button-camera"
        style={{ cursor: loading ? "none" : "pointer" }}
        onClick={() => {
          !loading && inputFileRef.current!.click();
        }}
      >
        {loading ? <SimpleLoader color="white" /> : <CameraIcon></CameraIcon>}
      </div>
    </div>
  );
};
