import * as Sentry from "@sentry/react";
import React, { ComponentType } from "react";
import { getEnv, isProd } from "../utils/env";

Sentry.init({
  dsn:
    "https://13288e4d1407448d98cf97b18f26f6d3@o31760.ingest.sentry.io/5278129",
  environment: getEnv(),
  enabled: isProd(),
});

Sentry.configureScope((scope) => scope.setTag("component", "mining-client"));

export const withSentry = <P extends Record<string, unknown>>(
  Komponent: ComponentType<P>
) => {
  const WithSentry = (props: P) => {
    return (
      <Sentry.ErrorBoundary
        fallback={<div>stala se chyba</div>}
        showDialog={false}
      >
        <Komponent {...props} />
      </Sentry.ErrorBoundary>
    );
  };

  return WithSentry;
};
