import { createFile } from "./file"
// export const createTestItem = async (url: string): Promise<PhotoProgress> => {
//     return {
//         id: uuid(),
//         file: await createFile(url),
//         loading: true,
//         result: null,
//     }

// }
export const createTestFiles = async (): Promise<File[]> => {
    const urls = ['/images/mtp_front.jpg',
        '/images/mtp_back2.png',
        // '/images/mtp_back.jpg',
        // '/images/ridicak_front_1.png',
        // '/images/ridicak2.png',
        '/images/ridicak3.png',
        // '/images/ridicak.jpg'
    ]
    const items = await Promise.all(urls.map(o => createFile(o)))
    return items
}


export const createTestFilesMedical = async (): Promise<File[]> => {
    const urls = [
        'https://i.imgur.com/s7dlZ9N.jpg', // zeman
        //'/images/mtp_front.jpg',
        // '/images/mtp_back2.png',
        // '/images/mtp_back.jpg',
        // '/images/ridicak_front_1.png',
        // '/images/ridicak2.png',
        // '/images/ridicak3.png',
        // '/images/motor.jpeg',
        // '/images/suprb.jpg',
        // '/images/predek.jpeg'
        // '/images/ridicak.jpg'
    ]
    const items = await Promise.all(urls.map(o => createFile(o)))
    return items
}


export const createTestFilesAutaky = async (): Promise<File[]> => {
    const urls = [
        //'/images/mtp_front.jpg',
        // '/images/mtp_back2.png',
        // '/images/mtp_back.jpg',
        // '/images/ridicak_front_1.png',
        // '/images/ridicak2.png',
        // '/images/ridicak3.png',
        '/images/motor.jpeg',
        '/images/suprb.jpg',
        '/images/predek.jpeg'
        // '/images/ridicak.jpg'
    ]
    const items = await Promise.all(urls.map(o => createFile(o)))
    return items
}


export const sampleResult = {
    data: {
        status: "ok",
        data: {
            pages: [
                {
                    page_number: 0,
                    gathered_data: {
                        mtp_document_date: { text: "30.06.2015", confidence: 0.0083 },
                        mtp_owner: { text: "KLUSAL RUDOLF", confidence: 0.4464 },
                        mtp_owner_operator: { text: "RNODRNOO", confidence: 0.0001 },
                        mtp_registration_date: {
                            text: "17.02.1999",
                            confidence: 0.018600000000000002,
                        },
                        mtp_registration_plate: {
                            text: "4U60848",
                            confidence: 0.28450000000000003,
                        },
                        mtp_residence: {
                            text:
                                "POD ZÁHORSKEM 670/31 PLZEŇ, SEVERNÍ PŘEDMĚSTÍ, 301 00",
                            confidence: 0.109,
                        },
                    },
                },
            ],
            debug_data: {
                hash: "ocr_mining_b0337704-7a3f-4865-b4dc-9a2903cee983",
                date: "2020-11-23",
                task_duration: 23083.100807038136,
            },
        },
    },
}