import "@firebase/functions";
import "@firebase/firestore";
import { extendTheme } from "@chakra-ui/react";

// import { use100vh } from "react-div-100vh";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  ChakraProvider,
  Flex,
  FormControl,
  FormLabel,
  // Checkbox,
  VStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stat,
  // StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { processCarPrice } from "./functions";
import Page from "./Page";

const COLUMNS = [
  "brand",
  "model",
  // "year_of_production",
  // "power",
  // "fuel",
  // "volume",
  // "4x4",
  // "transmission",
  // "body",
  // "number_of_doors",
  // "condition",
];

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    cyan: {
      "50": "#E7F8FD",
      "100": "#BCECFA",
      "200": "#92E0F7",
      "300": "#67D3F4",
      "400": "#3CC7F1",
      "500": "#11BBEE",
      "600": "#0E95BE",
      "700": "#0A708F",
      "800": "#074B5F",
      "900": "#032530",
    },
  },

  components: {
    Button: {
      defaultProps: {
        colorScheme: "cyan",
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: "cyan",
      },
    },
  },
});
export default () => {
  return (
    <ChakraProvider theme={theme}>
      <Page>
        <CarPrice />
      </Page>
    </ChakraProvider>
  );
};

export const CarPrice = () => {
  const [data, setData] = useState<any>();
  const [price, setPrice] = useState("");

  const { register, watch, formState, handleSubmit, control } = useForm({
    mode: "onChange",
  });
  const { isValid } = formState;
  console.log("isValid", isValid);
  useEffect(() => {
    fetch("https://storage.googleapis.com/cdn-ic-public/carprice/auta.json")
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  if (!data) {
    return <div>Loading</div>;
  }

  const onSubmit = async (values: any) => {
    setPrice("");
    console.log(values);

    // const r = { ...values, "4x4": values["4x4"] ? 1 : 0 };
    const result = await processCarPrice(values);
    const price = result.data?.data?.predicted_price;
    if (price) {
      var number2 = parseFloat(price);

      setPrice(number2.toLocaleString("cs-CZ", { maximumFractionDigits: 0 }));
    }

    console.log(result);
    window.scrollTo(0, document.body.scrollHeight);
  };
  const values = watch();
  console.log(values);

  const selectedPath = COLUMNS.map((c, index) => {
    return values[COLUMNS[index]] || undefined;
  });
  // const selectedPath = ["Skoda", "Citigo", 2011, 44];
  // console.log("xpxp", selectedPath);

  const _valuesBySelectedPath = (
    data: any,
    path: Array<String | number | undefined>
  ): any => {
    const p = [...path];
    const current = p.shift();
    // console.log("KUK", current, p);
    if (!current) {
      return [];
    }

    // muzou tam bejt cisla...
    // eslint-disable-next-line eqeqeq
    var selected = data.find((o: any) => o[0] == current);
    if (!selected) {
      // zrejme se zmenil vyber
      return [];
    }
    var valuesOfSelected = selected[1].map((o: any) => o[0]);
    // return selected;
    return [
      // data.map((o: any) => o[0]),
      valuesOfSelected.filter((o: any) => o !== ""),
      ..._valuesBySelectedPath(selected[1], p),
    ];
  };
  const valuesBySelectedPath = (
    data: any,
    path: Array<String | number | undefined>
  ): any => {
    // problme plus 1 - chybi tam root
    return [data.map((o: any) => o[0]), ..._valuesBySelectedPath(data, path)];
  };

  const pup = valuesBySelectedPath(data, selectedPath);
  // console.log("PUP", pup);

  const f = COLUMNS.map((column, index) => {
    let values: any[] = [];
    if (pup[index]) {
      values = pup[index];
    }
    // if (column === "brand") {
    // values = data.map((o: any) => o[0]);
    // }
    if (values.length === 1) {
      // console.log("beru", values);
      // setTimeout(() => {
      //   setValue(column, values[0]);
      // }, 1000);
    }
    // const { ref, ...rest } = register(column, { required: true });
    return (
      <FormControl isRequired key={column}>
        <FormLabel sx={{ textTransform: "capitalize" }}>{column}</FormLabel>

        <Select
          {...register(column, { required: true })}
          disabled={!values.length}
        >
          <option key="" value="" />
          {values.map((i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  });

  console.log(formState);
  return (
    <Flex width="full" align="center" justifyContent="center">
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing="12px">
            {f}
            <Controller
              name="year_of_production"
              rules={{ required: true }}
              defaultValue="2010"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControl isRequired>
                  <FormLabel>Year of production</FormLabel>
                  <NumberInput
                    step={1}
                    min={1990}
                    max={2021}
                    onChange={onChange}
                    value={value}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              )}
            />
            <FormControl>
              <FormLabel>Fuel</FormLabel>

              <Select {...register("fuel")} defaultValue="petrol">
                <option key="" value="" />
                {[
                  "petrol",
                  "diesel",
                  "hybrid",
                  "LPG",
                  "CNG",
                  "electric",
                  "other",
                ].map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Mileage (km)</FormLabel>
              <NumberInput
                step={10000}
                defaultValue={10000}
                min={0}
                max={1000000}
              >
                <NumberInputField {...register("mileage")} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Power (kW)</FormLabel>
              <NumberInput step={10} defaultValue={135} min={0} max={1000}>
                <NumberInputField {...register("power")} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Volume (ccm)</FormLabel>
              <NumberInput step={100} defaultValue={1300} min={300} max={10000}>
                <NumberInputField {...register("volume")} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl>
              <FormLabel>Car body</FormLabel>

              <Select {...register("body")} defaultValue="hatchback">
                <option key="" value="" />
                {[
                  "kombi",
                  "hatchback",
                  "SUV",
                  "MPV",
                  "sedan",
                  "liftback",
                  "cabrio",
                  "VAN",
                  "coupe",
                  "pickup",
                  "offroad",
                  "CUV",
                  "roadster",
                ].map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Transmission</FormLabel>

              <Select {...register("transmission")} defaultValue="manual">
                <option key="" value="" />
                {["manual", "automatic", "semi-automatic"].map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl>
              <Checkbox {...register("4x4", { required: false })}>4x4</Checkbox>
            </FormControl> */}
            <Button
              mt={4}
              isLoading={formState.isSubmitting}
              type="submit"
              disabled={!formState.isValid}
            >
              Submit
            </Button>
            <br></br>
          </VStack>
        </form>
        <br></br>
        {price && (
          <Stat
            sx={{
              background: "#16BCEE",
              color: "white",
              border: "1px solid #eee",
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <StatLabel>Predicted price</StatLabel>
            <StatNumber>{price} Kč</StatNumber>
            {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
          </Stat>
        )}
        <br></br>
        <br></br>
      </div>
    </Flex>
  );
};
