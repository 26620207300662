export enum Environment {
  Production = "production",
  Development = "development",
}

export const getEnv = () => {
  return process.env.NODE_ENV === "production"
    ? Environment.Production
    : Environment.Development;
};

export const isProd = (): boolean => {
  return getEnv() === Environment.Production;
};

export const isDev = () => !isProd();
