import { detect, BrowserInfo } from "detect-browser";

const isMiui = () => {
  // Example userAgent
  // Mozilla/5.0 (iPad; U; CPU OS 6_0_1 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A523 Safari/8536.25 XiaoMi/MiuiBrowser/12.2.6-g
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("xiaomi") || userAgent.includes("miuibrowser");
};

export const isIOS = () => {
  const detected = detectBrowser();
  return (
    detected?.os === "iOS" ||
    detected?.name === "ios" ||
    detected?.name === "ios-webview"
  );
};

export const detectBrowser = (): ReturnType<typeof detect> => {
  const detected = detect();

  const miui = new BrowserInfo("miui", "", "Android OS");
  return isMiui() ? miui : detected;
};

export const buildChromeIntentUrl = (): string => {
  const { protocol, host, pathname } = window.location;
  return `intent://${host}${pathname}#Intent;scheme=${protocol};package=com.android.chrome;end`;
};
