//@ts-ignore
import QRCode from "qrcode.react";
import React, { useEffect } from "react";
import { ReactComponent as RefreshIcon } from "./assets/refresh-24px.svg";
import { MiningData } from "./types";
import { trackEvent } from "./utils/tracking";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getColor(value: number) {
  //value from 0 to 1
  var hue = ((1 - value) * 120).toString(10);
  return ["hsl(", hue, ",100%,50%)"].join("");
}

interface Props {
  data: any;
  type?: string | undefined;
  dismiss?: () => void;
}

export const Result = ({ data, dismiss, type }: Props) => {
  const debugcode = data?.data?.data?.debug_data?.hash as string | undefined;
  var gh =
    data?.data?.data?.pages?.[0]?.gathered_data ||
    ({} as MiningData | undefined);
  console.log("GOGOGOG", gh);
  useEffect(() => {
    trackEvent("opened result screen");
  }, []);

  const renderContent = () => {
    console.log("GK", gh, data);
    if (data && !gh) {
      return (
        <div className="gt-title">
          Něco se nepovedlo, prosím zkuste to znovu později.
        </div>
      );
    }
    // novej format
    if (gh.fields) {
      var flds = {};
      const gombi = data?.data?.data?.pages?.map(
        (o: any) => o.gathered_data.fields
      );
      console.log("gombi", gombi);
      gombi.forEach((g: any) => {
        Object.entries(g).forEach(([k, v]) => {
          console.log(k, v);
          //@ts-ignore
          if (!flds[k]) {
            //@ts-ignore
            flds[k] = { texts: [], entities: [] };
          }
          //@ts-ignore
          flds[k].texts = [...flds[k].texts, ...v.texts];
          //@ts-ignore
          flds[k].entities = [...flds[k].entities, ...v.entities];
        });
      });

      console.log("FUGOGO", flds);
      gh = flds; //gh.fields;
    }
    console.log("GUJKI", gh);
    var keys = Object.keys(gh).sort((a, b) => {
      if (a === "mtp_vin") {
        return -1;
      }
      if (b === "mtp_vin") {
        return 1;
      }

      console.log(a);
      return 0;
    });
    return (
      <>
        <h3 style={{ textAlign: "left" }}>{type}</h3>
        {keys.map((key) => (
          <div key={key}>
            <div className="gt-title">
              {key.replace("mtp_", "").replace("dl_", "").replace(/_/g, " ")}{" "}
              {/* <span style={{ color: getColor(1 - gh[key].confidence) }}>
                  {Math.round(gh[key].confidence * 100)}%
                </span> */}
            </div>

            <div className="gt-text">
              {gh[key]?.texts
                ? gh[key]?.texts
                    .map((o: any) =>
                      o.text.replace(/"/g, "").replace(/&/g, " ")
                    )
                    .map((o: any, index: any, r: any) => [
                      o,
                      r.length === index + 1 ? null : <br />,
                    ])
                : gh[key]?.text.replace(/"/g, "").replace(/&/g, " ")}
            </div>
            <div className="gt-text"></div>
          </div>
        ))}
        {dismiss && debugcode && (
          <>
            <div className="gt-title">Debug code</div>
            <div className="gt-text">{debugcode}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <QRCode value={debugcode} />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="result">
        <div className="result-content">{renderContent()}</div>
      </div>
      {dismiss && (
        <div className="button-camera" style={{ width: 56 }} onClick={dismiss}>
          <RefreshIcon />
        </div>
      )}
    </>
  );
};
