//@ts-ignore
// import QRCode from "qrcode.react";
// import { v4 as uuid } from "uuid";
//@ts-ignore
import React, { useState } from "react";
import { useEffect } from "react";
import { PhotoProgress } from "./types";
import { Uploader } from "./utils/Uploader";
//@ts-ignore
// import generate from "firebase-auto-ids";

import "@firebase/functions";
import "@firebase/firestore";
// import { use100vh } from "react-div-100vh";

interface Props {
  showLoader: boolean;
  result: any;
  onImageSelected: (file: File) => void;
  //   onImageSelected
}

const setHTML = (selector: string, value: string) => {
  const el = document.getElementById(selector);

  if (el) {
    if (el instanceof HTMLInputElement) {
      el.value = value ?? "";
    } else {
      el.innerHTML = value ? value : "&nbsp;";
    }
    //@ts-ignore
    const pel = el.parentElement.parentElement as HTMLDivElement;
    // pel.style.background = "red";
    if (value) {
      pel.classList.remove("carculator-input-valid");
      pel.classList.add("carculator-input-valid");
    } else {
      pel.classList.remove("carculator-input-valid");
      // pel.classList.add("carculator-input-valid");
    }
  }
};

export const Uniqa = () => {
  const [items, setItems] = useState<PhotoProgress[]>([]);

  useEffect(() => {
    const o = items
      .map((i) => i.result?.data?.data?.pages[0]?.gathered_data.fields)
      .filter((o) => o);
    const r = Object.assign({}, ...o);
    console.log("PPPP", r);
    //@ts-ignore
    window.RRR = r;
    // const gd = result?.data?.data?.pages[0]?.gathered_data;
    const mapicka = {
      // "accident-insurance-capital-amount": "1234",
      "license-plate": r.registration_plate?.texts?.[0]?.text,
      palivo: r.power_type?.texts?.[0]?.text,
      //
      znacka: r.brand_typ_variant_version?.texts?.[0]?.text,
      typauta: r.vehicle_type?.texts?.[0]?.text,
      model: r.brand_model?.texts?.[0]?.text,
      "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_VIN:inpVOZIDLO_VIN":
        r.mtp_vin?.text,
      typvoz: r.vehicle_type?.texts?.[0]?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_JMENO:inpPARTNER_JMENO":
        r.dl_name_driver?.text,
      "policyholder-name": r.name_driver?.entities?.find(
        (e: any) => e.type === "name_driver_parsed"
      )?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_PRIJMENI:inpPARTNER_PRIJMENI":
        r.surname_driver?.texts?.[0]?.text,
      "policyholder-surname": r.surname_driver?.texts?.[0]?.text,
      "vstupInfo:PanelVstupniInfo:VI_PATTERN:inpVI_PATTERN":
        r.mtp_registration_plate?.text,

      "policyholder-ssn": r.birth_number?.texts?.[0]?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:adresaAutocomplete_input":
        r.mtp_residence?.text,

      year: r.registration_date?.texts?.[0]?.text?.substr(-4),

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:obec:inputText":
        r.residence?.entities?.find(
          (r: any) => r.type === "residence_city_parsed"
        )?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:castObce:inputText":
        r.mtp_residence_city2_parsed?.text,
      "policyholder-zip-code": r.residence?.entities?.find(
        (r: any) => r.type === "residence_post_code_parsed"
      )?.text,
      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:ulice:inputText":
        r.mtp_residence_address_raw_parsed?.text,
      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:cisloPopisne:inputText":
        r.mtp_residence_land_registry_number_parsed?.text,
      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:cisloOrientacni:inputText":
        r.mtp_residence_house_number_parsed?.text,

      "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_RC:INPTITLE":
        r.mtp_name_title_parsed?.text,
    };

    //@ts-ignore
    Object.entries(mapicka).forEach(([key, val]) => setHTML(key, val));
  }, [items]);
  // console.log(docId);

  // if (!docId) {
  // return null;
  // }

  return (
    <>
      <Uploader onChange={setItems} classify={true} mine={true} />
    </>
  );
};
