import React from "react";

import "./Loader.css";

interface Props {
  color: string;
}
export default ({ color }: Props) => {
  return (
    <div className={"dribble"}>
      <span className={"point"} style={{ backgroundColor: color }} />
      <div className={"points"}>
        <span style={{ backgroundColor: color }} />
        <span style={{ backgroundColor: color }} />
        <span style={{ backgroundColor: color }} />
      </div>
    </div>
  );
};
