//@ts-ignore
import React, { useState } from "react";
import { useEffect } from "react";
import { PhotoProgress } from "./types";
import { Uploader } from "./utils/Uploader";
import { flatMap } from "lodash";
interface Props {
  showLoader: boolean;
  result: any;
  onImageSelected: (file: File) => void;
  //   onImageSelected
}

// const setHTML = (selector: string, value: string) => {
//   const els = document.querySelectorAll(`[name=${selector}]`);
//   els.forEach((el) => {
//     if (el) {
//       if (el instanceof HTMLInputElement) {
//         el.value = value ?? "";
//       } else {
//         el.innerHTML = value ? value : "&nbsp;";
//       }
//       if (value) {
//         el.classList.remove("not-filled");
//         el.classList.add("filled-ok");
//       } else {
//         el.classList.remove("filled-ok");
//         el.classList.add("not-filled");
//       }
//     }
//   });
// };

export const MedicalReport = () => {
  const [items, setItems] = useState<PhotoProgress[]>([]);

  useEffect(() => {
    const o = items
      .map((i) => {
        return i.result?.data?.data?.pages.map(
          (p: any) => p.gathered_data.fields
        );
        // i.result?.data?.data?.pages[0]?.gathered_data;
      })
      .filter((o) => o);

    const r = Object.assign({}, ...o);
    const g = flatMap(r, (o, index) => {
      return Object.entries(o).map(([k, v]) => {
        return [
          index + 1 + "_" + k,
          //@ts-ignore
          v?.texts.map((o: any) => o.text).join(", "),
        ];
      });
    });
    console.log("OUO,=", r, g);
    // const ogo = Object.entries<any>(r?.fields || []).map(([k, v]) => {
    //   return [k, v?.texts.map((o: any) => o.text).join(", ")];
    // });
  }, [items]);
  return (
    <Uploader
      onChange={setItems}
      mine={false}
      classify={false}
      medical={true}
      showMobileQr={false}
    />
  );
};
