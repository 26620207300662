//@ts-ignore
import React, { useState } from "react";
import { useEffect } from "react";
import { PhotoProgress } from "./types";
import { Uploader } from "./utils/Uploader";
interface Props {
  showLoader: boolean;
  result: any;
  onImageSelected: (file: File) => void;
  //   onImageSelected
}

const setHTML = (selector: string, value: string) => {
  const els = document.querySelectorAll(`[name=${selector}]`);
  els.forEach((el) => {
    if (el) {
      if (el instanceof HTMLInputElement) {
        el.value = value ?? "";
      } else {
        el.innerHTML = value ? value : "&nbsp;";
      }
      if (value) {
        el.classList.remove("not-filled");
        el.classList.add("filled-ok");
      } else {
        el.classList.remove("filled-ok");
        el.classList.add("not-filled");
      }
    }
  });
};

export const Csob = () => {
  const [items, setItems] = useState<PhotoProgress[]>([]);

  useEffect(() => {
    const o = items
      .map((i) => i.result?.data?.data?.pages[0]?.gathered_data.fields)
      .filter((o) => o);
    const r = Object.assign({}, ...o);
    console.log("VVVVPVPVP", r);

    const residence = r.residence?.entities;

    // const gd = result?.data?.data?.pages[0]?.gathered_data;
    const mapicka = {
      tovarniZnacka0: r.brand_typ_variant_version?.texts?.[0]?.text,
      modelVozidla0: r.brand_model?.texts?.[0]?.text,
      // "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_VIN:inpVOZIDLO_VIN":
      //   r.mtp_vin?.text,
      // "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_DRUH_VOZIDLA:inpVOZIDLO_DRUH_VOZIDLA":
      //   r.mtp_vehicle_type?.text,

      // "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_JMENO:inpPARTNER_JMENO":
      //   r.dl_name_driver?.text,
      vinikJmeno: r.name_driver?.entities?.find(
        (o: any) => o.type === "name_driver_parsed"
      )?.text,

      // "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_PRIJMENI:inpPARTNER_PRIJMENI":
      //   r.dl_surname_driver?.text,
      vinikPrijmeni: r.surname_driver?.texts?.[0]?.text,
      // "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_RZ:inpVOZIDLO_RZ":
      //   r.mtp_registration_plate?.text,
      registracniZnackaVozdila0: r.registration_plate?.texts?.[0]?.text,
      vinikRegistracniZnacka: r.registration_plate?.texts?.[0]?.text,

      vinikRodneCislo: r.birth_number?.texts?.[0]?.text,

      // "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:adresaAutocomplete_input":
      //   r.mtp_residence?.text,

      // "havarie:PanelVozidlo_POJISTENY:inside:PanelVozidlo_POJISTENY:VOZIDLO_ROK_VYROBY:inpVOZIDLO_ROK_VYROBY":
      //   r.mtp_registration_date?.text,

      kontatniAdresaobec: residence?.find(
        (o: any) => o.type === "residence_city_parsed"
      )?.text,
      // "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:castObce:inputText":
      //   r.mtp_residence_city2_parsed?.text,
      // "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:j_idt160:ruianAdresa:psc:inputText":
      //   r.mtp_residence_post_code_parsed?.text,
      kontatniAdresaulice: residence?.find(
        (o: any) => o.type === "residence_address_raw_parsed"
      )?.text,

      kontatniAdresacisloPopisne: residence?.find(
        (o: any) => o.type === "residence_address_code_parsed"
      )?.text,

      // "havarie:PanelPartner_POJISTENY:inside:PanelPartner_POJISTENY:PARTNER_RC:INPTITLE":
      //   r.mtp_name_title_parsed?.text,
    };

    //@ts-ignore
    Object.entries(mapicka).forEach(([key, val]) => setHTML(key, val));
  }, [items]);
  return <Uploader onChange={setItems} mine={true} classify={true} />;
};
